<template>
    <v-container fluid>
      
        <v-row style="margin-bottom: 100px">
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>

            <v-col cols="12">
                <v-card>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete solo
                                    class="ma-0 pa-0"
                                    hide-details=true v-model="officeidModel" :items="officeidLists" default="" item-value="office_id" item-text="office" label="OFFICE" clearable @change="(event) => getDepartmentByoffice(event)"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete solo
                                    class="ma-0 pa-0"
                                    hide-details=true v-model="departmentModel" :items="departmentLists" default="" item-value="dept_id" item-text="dept_name" label="DEPARTMENT" @click="() => checkDeptList()" clearable></v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-0"
                                    hide-details=true v-model="approvedModel" :items="approvedLists" default="" item-value="value" item-text="text" label="APPROVED" clearable></v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-0"
                                    hide-details=true v-model="statusModel" :items="statusLists" default="" item-value="value" item-text="text" label="STATUS" clearable></v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_from"
                                        label="Date From"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        label="Date to"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-btn class="mr-2" color="info" elevation="2" large @click="search()">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>

            <v-col cols="12">
                <v-card v-show="display == 'none' ? false: true">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Result
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search PO ID"
                                        single-line
                                        @keyup="searchByPrId"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>

                                <v-data-table
                                    :headers="dtHeaderTitle"
                                    :items="searchResult"
                                    :options.sync="options"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItems"
                                    :page.sync="pagination"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                
                                <template v-slot:[`item.status`]="{ item }">
                                    <v-chip class="ma-2" color="primary" text-color="white" v-if="item.stat === 'O'" >
                                        OPEN
                                    </v-chip>
                                    <v-chip class="ma-2" color="red" text-color="black" v-if="item.stat === 'R'" >
                                        REJECT
                                    </v-chip>
                                    <v-chip class="ma-2" color="green" text-color="white" v-if="item.stat === 'C'">
                                        CLOSED
                                    </v-chip>
                                </template>

                                <template v-slot:[`item.dt_aprv`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                                <div v-if="$store.getters.UsernameUser === user_approve1">
                                                    <v-chip class="ma-2" color="primary" v-if="item.dt_aprv === '01/01/00 12:00' && item.dt_rjc === '01/01/00 12:00'" @click="setApprove1(item)" v-on="on" >
                                                        <v-icon center> 
                                                            mdi-check-circle
                                                        </v-icon>
                                                            CONFIRM
                                                    </v-chip>

                                                    <v-chip class="ma-2" color="red" v-else-if="item.dt_rjc != '1900-01-01 00:00:00.000'" >
                                                        {{item.dt_rjc}}
                                                    </v-chip>
                                                    
                                                    <v-chip class="ma-2" color="success" v-else>
                                                            {{item.dt_aprv}}
                                                    </v-chip>
                                                </div>
                                                <div v-else>
                                                    <v-chip class="ma-2 text-white" color="red" v-if="item.dt_aprv === '01/01/00 12:00'" >
                                                            NOT APPROVED
                                                    </v-chip>
                                                    
                                                    <v-chip class="ma-2" color="success" v-else>
                                                            {{item.dt_aprv}}
                                                    </v-chip>
                                                </div>
                                        </template>
                                        <span>Confirm</span>
                                    </v-tooltip>
                                </template>

                                <template v-slot:[`item.dt_aprv2`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                                <div v-if="$store.getters.UsernameUser === user_approve2">
                                                    <v-chip class="ma-2" color="primary" v-if="item.dt_aprv != '01/01/00 12:00' && item.dt_aprv2 === '01/01/00 12:00'  && item.dt_rjc2 === '01/01/00 12:00'" @click="setApprove2(item)" v-on="on" >
                                                        <v-icon center> 
                                                            mdi-check-circle
                                                        </v-icon>
                                                            CONFIRM
                                                    </v-chip>

                                                    <v-chip class="ma-2" color="red" v-else-if="item.dt_rjc2 != '01/01/00 12:00'" >
                                                        {{item.dt_rjc2}}
                                                    </v-chip>
                                                    
                                                    <v-chip class="ma-2" color="success" v-else>
                                                            {{item.dt_aprv2}}
                                                    </v-chip>
                                                </div>
                                                <div v-else>
                                                    <v-chip class="ma-2 text-white" color="red" v-if="item.dt_aprv2 === '01/01/00 12:00'">
                                                            NOT APPROVED
                                                    </v-chip>
                                                    
                                                    <v-chip class="ma-2" color="success" v-else>
                                                            {{item.dt_aprv2}}
                                                    </v-chip>
                                                </div>
                                        </template>
                                        <span>Confirm</span>
                                    </v-tooltip>
                                </template>

                                <template v-slot:[`item.detailItem`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                mdi-eye
                                            </v-icon>
                                        </template>
                                        <span>Show Detail</span>
                                    </v-tooltip>
                                </template>
                                
                                </v-data-table>

                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            
        </v-row>

        <v-dialog v-model="dialogModalDetail" max-width="1000px">                
            <v-card>
                <v-card-title>Detail PO Item</v-card-title>
                <v-card-text>
                        <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                
                                <v-data-table
                                    :headers="dtHeaderTitleDetail"
                                    :items="searchResultDetail"
                                    :options.sync="optionsDetail"
                                    :loading="loadingDetail"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItemsDetail"
                                    :page.sync="paginationDetail"
                                    page-count="10"
                                    class="elevation-1"
                                ></v-data-table>

                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialogModalDetail">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>

        <v-dialog
                v-model="dialogApprove1"
                persistent
                max-width="500"
                >
            <v-card>
                <v-card-title class="text-h5">
                Are you sure you approve this PR?
                </v-card-title>
                <v-card-text>Memo : {{ txtDetailPR }}</v-card-text>
                <v-card-actions>
                <v-btn
                class="pull-left"
                    color="green darken-1"
                    text
                    @click="dialogApprove1 = false"
                >
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    class="ma-2"
                    color="red"
                    text-color="white"
                    @click="submitReject()"
                    >
                    <v-icon light>mdi-close-circle-outline</v-icon>

                        Reject
                    <template v-slot:loader>
                        <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                        </span>
                    </template>
                </v-btn>
                <v-btn
                    class="ma-2"
                    color="green darken-1"
                    text-color="white"
                    @click="submitApprove()"
                    >
                    <v-icon>mdi-check-circle-outline</v-icon>
                        Agree
                    <template v-slot:loader>
                        <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                        </span>
                    </template>
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
                v-model="dialogApprove2"
                persistent
                max-width="500"
                >
            <v-card>
                <v-card-title class="text-h5">
                Are you sure you approve this PR?
                </v-card-title>
                <v-card-text>Memo : {{ txtDetailPR2 }}</v-card-text>
                <v-card-actions>
                <v-btn
                class="pull-left"
                    color="green darken-1"
                    text
                    @click="dialogApprove2 = false"
                >
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    class="ma-2"
                    color="red"
                    text-color="white"
                    @click="submitReject2()"
                    >
                    <v-icon light>mdi-close-circle-outline</v-icon>

                        Reject
                    <template v-slot:loader>
                        <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                        </span>
                    </template>
                </v-btn>
                <v-btn
                    class="ma-2"
                    color="green darken-1"
                    text-color="white"
                    @click="submitApprove2()"
                    >
                    <v-icon>mdi-check-circle-outline</v-icon>
                        Agree
                    <template v-slot:loader>
                        <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                        </span>
                    </template>
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>    
</template>
<script>


export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'MKI',
                disabled: false,
                href: '/admin/mki/dashboard',
                },
                {
                text: 'APPROVAL',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Purchase Request',
                disabled: true,
                href: 'breadcrumbs_link_2',
                }
            ],
             snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: null,
                text: null,
                timeout: null,
                title: null,
                visible: false
            },
            officeidModel: '',
            officeidLists: [],
            departmentModel: '',
            departmentLists: [],
            approvedModel: '',
            approvedLists: [
                {
                    text: 'ALL',
                    value: ''
                },
                {
                    text: 'NOT APPROVED 1',
                    value: 'approve1'
                },
                {
                    text: 'NOT APPROVED 2',
                    value: 'approve2'
                },
            ],
            statusModel: '',
            statusLists: [
                {
                    text: 'ALL',
                    value: ''
                },
                {
                    text: 'OPEN',
                    value: 'O'
                },
                {
                    text: 'CLOSE',
                    value: 'C'
                },
            ],
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            searchResult: [],
            dtHeaderTitle: [
                { value: 'pr_id', text: 'PR ID'},
                { value: 'dt_pr', text: 'Date PR'},
                { value: 'dept_name', text: 'Department'},
                { value: 'pic_name', text: 'Requester'},
                { value: 'memo_txt', text: 'Memo'},
                { value: 'status', text: 'Status'},
                { value: 'dt_aprv', text: 'Approve 1'},
                { value: 'dt_aprv2', text: 'Approve 2'},
                { value: 'detailItem', text: 'Detail'},
            ],
            loading: false,
            options: {},
            totalItems: 10,
            pagination: 1,
            searchResultDetail: [],
            dtHeaderTitleDetail: [
                { value: 'pr_id', text: 'PR ID'},
                { value: 'description', text: 'Article'},
                { value: 'unit_meas', text: 'Unit Meas.'},
                { value: 'qty', text: 'Qty'},
                { value: 'wgt', text: 'Weight'},
                { value: 'qty_po', text: 'Qty PO'},
                { value: 'wgt_po', text: 'Weight PO'},
                { value: 'qty_rcv', text: 'Qty Receive'},
                { value: 'wgt_rcv', text: 'Wgt Receive'},
                { value: 'remark', text: 'Remark'},
            ],
            loadingDetail: false,
            optionsDetail: {},
            totalItemsDetail: 10,
            paginationDetail: 1,
            display: "none",
            dialogModalDetail: false,
            detailPr_id: "",
            user_approve1: '',
            user_approve2: '',
            data_var: {
                entity_id : 'MKI',
                appl_id : 'WEBMKI'
            },
            approve: '',
            approve2: '',
            dialogApprove1: false,
            txtDetailPR: '',
            dialogApprove2: false,
            txtDetailPR2: '',
            rejectApprove: false,
            pr_id_appv: ''
        }
    },
    mounted(){
        this.getOfficeAndDepartment()
        // this.getPullResult(1, 10, false)
        this.getEnvConf()
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'APRVPR1') {
                        this.user_approve1 = res.data.data[i]['var_value']
                        if (this.user_approve1 != null) {
                            this.approvedModel = 'approve1'
                            //console.log(res.data.data[i]['var_value'])
                        }
                    }
                    
                    if (res.data.data[i]['var_id'] === 'APRVPR2') {
                        this.user_approve2 = res.data.data[i]['var_value']
                        if (this.user_approve2 != null) {
                            this.approvedModel = 'approve2'
                            //console.log(res.data.data[i]['var_value'])
                        }
                    }

                    console.log(this.user_approve2);

                    if (this.approve != '') {

                    }

                }

                // this.getPullResult(1, 10, false)

            })

        },

        getOfficeAndDepartment(){
            axios.get(`${process.env.VUE_APP_URL}/api/mki/purchase_request`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.officeidLists = res.data.officeidlist
            })
        },

        getDepartmentByoffice(id){
            axios.get(`${process.env.VUE_APP_URL}/api/mki/purchase_request/getDepartmentByOffice?officeid=${id ? id : ""}`,
            { 
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` 
                    }
            }
            )
            .then(res => {
                this.departmentLists = res.data.departmentlist
            })
        },

        checkDeptList(){

            if(this.officeidModel === '' || this.officeidModel === null ){

                this.snackbar = {
                    color: "warning",
                    mode: "multi-line",
                    position: "top",
                    timeout: 3000,
                    title: "Warning",
                    text: "Please select office first !",
                    visible: true
                };

            }

        },

        datetime_temp(val) {

            var dateStr

            dateStr = new Date(val).toISOString().substr(0, 10).replace(/-/g,"")

            return dateStr

        },

        async search() {
            this.getPullResult(1, 10, true)
        },

        async searchByPrId() {
            this.getPullResult(1, 10, false)
        },

        showItem(id){

            this.dialogModalDetail = true
            this.loadingDetail = true
            this.detailPr_id = id.pr_id
            this.getpullDetail(1,10,this.detailPr_id)

        },

        async getPullResult(page = 1, itemsPerPage = 10, search){

            this.display = "block"
            this.loading = true

            var startDate = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var endDate = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''
            
            await axios.get(`${process.env.VUE_APP_URL}/api/mki/purchase_request/getPurchaseRequestResult?pr_id=${this.$store.state.text ? this.$store.state.text : ''}&txtOffice=${this.officeidModel.trim() == "" || this.officeidModel == null ? this.officeidModel : ""}&txtDepartment=${this.departmentModel.trim() ? this.departmentModel.trim() : ""}&txtApproved=${this.approvedModel ? this.approvedModel : ""}&txtStatus=${this.statusModel ? this.statusModel : ""}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}&paginate=${itemsPerPage}&page=${page > 1 ? page : ''}`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` 
                } 
            })
            .then(res => {
                
                this.searchResult = res.data.data
                this.loading = false
                this.totalItems = res.data.total
                this.pagination = res.data.current_page
                
            });
            
        },

        async getpullDetail(page = 1, itemsPerPage = 10, id){

            await axios.get(`${process.env.VUE_APP_URL}/api/mki/purchase_request/getPurchaseRequestResultDetail?pr_id=${id}&paginate=${itemsPerPage}&page=${page > 1 ? page : ''}`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` 
                } 
            })
            .then(res => {
                
                this.searchResultDetail = res.data.data
                this.loadingDetail = false
                this.totalItemsDetail = res.data.total
                this.paginationDetail = res.data.current_page
            });
            
        },

        closeDialogModalDetail(){
            this.dialogModalDetail = false
        },

        setApprove1(id){

            this.txtDetailPR = id.memo_txt
            this.dialogApprove1 = true
            this.pr_id_appv1 = id.pr_id

        },

        async submitApprove(){

            await axios.post(`${process.env.VUE_APP_URL}/api/mki/purchase_request/updateApprovalPR`, {
                'pr_id': this.pr_id_appv1
            }, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` 
                } 
            }).then(res => {

                this.dialogApprove1 = false
                this.getPullResult(1, 10, false)

            }).catch(err => {
                    
                console.log(err.response.data.message)
                
            })

        },

        async submitReject(){

            await axios.post(`${process.env.VUE_APP_URL}/api/mki/purchase_request/rejectApprovalPR`, {
                'pr_id': this.pr_id_appv1
            }, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` 
                } 
            }).then(res => {

                this.dialogApprove1 = false
                this.getPullResult(1, 10, false)

            }).catch(err => {
                    
                console.log(err.response.data.message)
                
            })

        },

        setApprove2(id){

            this.txtDetailPR2 = id.memo_txt
            this.dialogApprove2 = true
            this.pr_id_appv = id.pr_id

        },

        async submitApprove2(){

            await axios.post(`${process.env.VUE_APP_URL}/api/mki/purchase_request/updateApprovalPRDua`, {
                'pr_id': this.pr_id_appv
            }, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            }).then(res => {

                this.dialogApprove2 = false
                this.getPullResult(1, 10, false)

            }).catch(err => {
                    
                console.log(err.response.data.message)
                
            })

        },

        async submitReject2(){

            await axios.post(`${process.env.VUE_APP_URL}/api/mki/purchase_request/rejectApprovalPRDua`, {
                'pr_id': this.pr_id_appv
            }, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` 
                } 
            }).then(res => {

                this.dialogApprove2 = false
                this.getPullResult(1, 10, false)

            }).catch(err => {
                    
                console.log(err.response.data.message)
                
            })

        },
        
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.display === "block") {
                    this.getPullResult(page, itemsPerPage)
                }
            },
            deep: true,
        },
        optionsDetail: {
            handler () {
                const { page, itemsPerPage } = this.optionsDetail
                if (this.detailPr_id != "") {
                    this.getpullDetail(page, itemsPerPage, this.detailPr_id)
                }
            },
            deep: true,
        }
    }
    
}
</script>